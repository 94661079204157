.App {
   padding-bottom: 20px;
   /* max-width: 960px; */
   margin: 0 auto;
}

.App__header {
   padding-left: 20px;
   padding-right: 20px;
}
 
.App__main {
   margin-top: 60px;
}

.red {
   color: red;
}

@media screen and (min-width: 1000px) {
   .main {
      width: 1000px;
   }
}

@media screen and (min-width: 768px) {
   .main {
      margin-right: auto;
      margin-left: auto;
   }

   .main-nav {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
   }

   .main-nav li {
      margin: 0;
   }

   .nav-links {
      margin-left: 40px;
   }

   .logo {
      margin-top: 0;
   }

   .navbar-toggle {
      display: none;
   }

   .logo:hover,
   .nav-links:hover {
      color: rgba(255, 255, 255, 1);
   }
   .App__main {
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
   }
}